import styled from "styled-components";
import logo from './image 11.svg'
import poster1 from './Group 2804.png'
import poster2 from './Group 2805.png'
import poster3 from './Group 2806.png'
import poster4 from './Group 2808.png'
import icon from './fbe5cb91cb9531d98cb8903c36e5799.png'
import importAllImg from "./helpers/import-all-imgs";
import {useState} from "react";
import AccordionPanel from "./components/AccordionPanel";
import PersonInfo from "./components/PersonInfo";

const imgs = importAllImg(require.context('./imgs', false, /\.(png|jpe?g|svg)$/));

function App() {

  const [tab, setTab] = useState(0)
  const [pastTab, setPastTab] = useState(0)


  const schedule = [
    { time: '9:15 to 9:30', content: 'Opening Remarks' },
    {
      time: '9:30 to 10:30',
      content: 'Session 1: An overview of the historical development of foreign/second language teaching: Highlights of historical changes in theory and practice\n',
      detail:
        'This session traces the historical development of foreign/second language teaching. It focuses on how major linguistic theories have influenced or shaped language teaching practices over time, including prescriptivism, structural linguistics/behaviorism, generative linguistics, systemic functional linguistics (together with the Speech Act theory and the Communicative Competence construct), and cognitive linguistics (along with emergentism, usage-based theory, and corpus linguistics).  \n'
    },
    { time: '10:30 to 10:50', content: 'Break' },
    {
      time: '10:50 to 11:50',
      content: 'Session 2: Contemporary language teaching theories and practices',
      detail: 'This session provides 1) a discussion of how contemporary linguistic theories, such as systemic functional linguistics, cognitive linguistics, and corpus linguistics, have helped bring forth significant advancements or changes in foreign/second language teaching and 2) a brief mention of how AI may impact or even revolutionize language teaching.   '
    },
    { time: '11:50 to 12:00', content: 'Q&A' },
    { time: '12:00 to 13:30', content: 'Break' },
    { time: '13:30 to 14:30',
      content: 'Session 3: Using Generative AI tools (e.g., ChatGPT/Gemini/文心一言) for language learning/teaching',
      detail: 'Based on existing published research, this session explores, with concrete examples, the use of generative chatbots (such as ChatGPT, Gemini, and文心一言) in foreign/second language learning and teaching. Specifically, the speech first illustrates the main specific ways in which chatbots can be used in language education along with their benefits for enhancing language learning experience and gains. Then, the speech discusses the limitations of chatbots, and the challenges involved in using them in language teaching, including ethical and practical concerns. The speech ends with a brief comparison between corpora and chatbots regarding their respective strengths and weaknesses for foreign/second language learning and teaching.'
    },
    { time: '14:30 to 14:50', content: 'Break' },
    { time: '14:50 to 15:50',
      content: 'Session 4: Online Resources for Teachers',
      detail: 'Having computers and cellphones in the classroom are a wonderful new tool available to teachers.  We have the opportunity to integrate technology into lessons like never before. In many cases, we can instantly use videos and media in our lectures and guide our students to needed resources.  But finding the best resources for classrooms and students can be difficult. In this presentation, we will discuss specific websites to use for resources in academic writing, pronunciation, class design, class activities and reading resources.\n' + '<br/>' +
        '-Rachel’s English\n' + '<br/>' +
        '-Breaking News English\n' + '<br/>' +
        '-News in Levels\n' + '<br/>' +
        '-PW.org\n'
    },
    { time: '15:50 to 16:00', content: 'Q&A, Closing Remarks' }
  ];

  const schedule2 = [
    { time: '09:00 to 09:05', content: 'Opening Remarks' },
    { time: '09:05 to 10:05', content: 'Session 1: Academic writing: An overview covering the genre/style and structure/components of the research article (RA)' },
    { time: '10:05 to 10:30', content: 'Break' },
    { time: '10:30 to 11:30', content: 'Session 2: Academic writing: An overview/introduction of the use of corpora and the appropriate use of GenAI (ChatGPT) in enhancing academic writing' },
    { time: '13:30 to 14:30', content: 'Session 3: Academic Writing: Grammar and Vocabulary (particularly from Chinese EFL writers’ perspective)' },
    { time: '14:30 to 15:00', content: 'Break' },
    { time: '15:00 to 16:00', content: 'Session 4: Continuation of session 3 with more examples and hands-on activities' }
  ];

  const schedule3 = [
    { time: '09:00 to 09:05', content: 'Opening Remarks' },
    { time: '09:05 to 10:05', content: 'Session 1: Academic writing: Coherence/cohesion and citation (including paraphrasing and summarizing)' },
    { time: '10:05 to 10:30', content: 'Break' },
    { time: '10:30 to 11:30', content: 'Session 2: Academic writing: Appropriate research topics and methodologies, including data collection/analysis (if time permits, how to respond to editors/reviewers’ review comments and revision requests may be covered)' },
    { time: '13:30 to 14:30', content: 'Session 3: Language Teaching: Using approaches/practices/strategies that can make language teaching more engaging, interesting, effective, and empowering. It starts with theoretical/practical rationales for such teaching and moves onto specific examples of teaching activities and strategies' },
    { time: '14:30 to 15:00', content: 'Break' },
    { time: '15:00 to 16:00', content: 'Session 4: Continuation with examples of activities and strategies of Session 3' }
  ];

  const presenters = [
    {
      id: 1,
      showTitle: true,
      img: imgs['image 2081.svg'],
      short: 'Dilin Liu, Ph.D., is Professor Emeritus of Applied Linguistics/TESOL in the English Department at the University of Alabama, USA. His research focuses on the description, learning, and teaching of English grammar and vocabulary using cognitive- and corpus-linguistic approaches. He has published extensively with over 100 publications, including seven books and numerous journal articles/book chapters. His articles have appeared in over 20 different SSCI journals in linguistics/applied linguistics, such as Applied Linguistics, Cognitive Linguistics, ELT Journal, English for Specific Purposes, Foreign Language Annals, International Journal of Applied Linguistics, International Journal of Corpus Linguistics, International Journal of Lexicography, IRAL, Journal of English for Academic Purposes, Journal of English Linguistics, Journal of Pragmatics, Journal of Psycholinguistic Research,',
      all: 'Dilin Liu, Ph.D., is Professor Emeritus of Applied Linguistics/TESOL in the English Department at the University of Alabama, USA. His research focuses on the description, learning, and teaching of English grammar and vocabulary using cognitive- and corpus-linguistic approaches. He has published extensively with over 100 publications, including seven books and numerous journal articles/book chapters. His articles have appeared in over 20 different SSCI journals in linguistics/applied linguistics, such as Applied Linguistics, Cognitive Linguistics, ELT Journal, English for Specific Purposes, Foreign Language Annals, International Journal of Applied Linguistics, International Journal of Corpus Linguistics, International Journal of Lexicography, IRAL, Journal of English for Academic Purposes, Journal of English Linguistics, Journal of Pragmatics, Journal of Psycholinguistic Research,  Language and Cognition, Language Teaching, Language Teaching Research, Modern Language Journal, Second Language Research, Studies in Second Language Acquisition, and TESOL Quarterly. He is on the 2023 world top 2% most highly cited scientists (authors) list in the field of linguistics. He has served or is serving on the editorial boards of over a dozen journals including ELT Journal, Journal of English for Academic Purposes, International Journal of Lexicography, Lingua, System, TESOL Quarterly, and TESOL Journal. He is currently co-editor of the Cell Press/Elsevier SCI journal Heliyon Education in charge of submissions on linguistics and applied linguistics (language teaching). A frequently invited speaker, Professor Liu has given many plenary/keynote and other invited speeches/lectures at conferences and universities in various countries and regions around the world, including Brazil, Cambodia, China, Hong Kong, Japan, Macau, Singapore, South Korea, Taiwan, the U.S., and Vietnam.'
    },
    {
      id: 2,
      showTitle: false,
      img: imgs['d28eb6b05a4c02529bd998f1e570ed1.jpg'],
      short: 'Damien Shuck is a participant in the English Language Specialist Program and English Language Fellowship Program, U.S. Department of State, Bureau of Education and Cultural Affairs exchange programs.  Damien Shuck is currently a Global Business English Instructor at Fort Hays State University in Zhengzhou, China.',
      all: 'Damien Shuck is a participant in the English Language Specialist Program and English Language Fellowship Program, U.S. Department of State, Bureau of Education and Cultural Affairs exchange programs.  Damien Shuck is currently a Global Business English Instructor at Fort Hays State University in Zhengzhou, China. Damien received an MFA from the University of Southern Maine. His book of poetry, The Drowning Room, was the winner of the 2013 New American Poetry Award.'
    }
  ]

  const isMobile = window.innerWidth <= 991;

  return (
    <Container>
      <div className='title'>RELO EVENTS</div>
      <div className='middle-panel'>
        <img src={imgs['image 11 (1).svg']} />
        {/*<div>*/}
        {/*  RELO English Teaching Seminar <br/>*/}
        {/*  Chengdu & Guangzhou*/}
        {/*</div>*/}
      </div>


      <div className='red-line'>
        {isMobile && 'PAST EVENTS'}
      </div>
      <div className='list-panel'>
        <div className='left-text'>
          Past <br/>
          Events
        </div>

        <div className='list-right'>

          <div style={{display: pastTab === 0 ? '' : 'none'}}>
            <div className='list-right-title'>
              RELO English Teaching Seminar Guangzhou
            </div>
            <div className='list-right-sub-title'>Academic Writing for Publication Workshop</div>
            <div className='list-right-sub-date'>
              July 22nd 2024
            </div>
            <div className='list-item'>
              <div className='video-panel'>
                <video
                  preload="none"
                  width={isMobile? '100%' : '628px'}
                  src={'https://file.udty.tech/20240723/1-merged-1721698942967%20%282%29-00.00.00.000-04.00.49.000.mp4'}
                  style={{outline: "none", objectFit: 'cover'}}
                  controls
                  poster={imgs['3b0a1d4fd8fa435cb6246152ba8b0c9.jpg']}
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
              </div>
            </div>


            <div className='list-right-sub-date'>
              July 23rd 2024
            </div>
            <div className='list-item'>
              <div className='video-panel'>
                <video
                  preload="none"
                  width={isMobile? '100%' : '628px'}
                  src={'https://file.udty.tech/20240729/23-cut-merged-1722241666622.mp4'}
                  style={{outline: "none", objectFit: 'cover'}}
                  controls
                  poster={imgs['6a34d59f409927506ced88a07211846.jpg']}
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
              </div>
            </div>


            <div className='list-right-title'>
              RELO English Teaching Seminar Chengdu
            </div>
            <div className='list-right-sub-title'>Practical Strategies for Effective English Teaching</div>
            <div className='list-right-sub-date'>
              July 19th 2024
            </div>

            <div className='list-item'>
              <div className='video-panel'>
                <video
                  preload="none"
                  width={isMobile? '100%' : '628px'}
                  src={'https://file.udty.tech/20240729/19-cut-merged-1722241539756.mp4'}
                  style={{outline: "none", objectFit: 'cover'}}
                  controls
                  poster={imgs['28be790760ba78653f560193ef73ee1.jpg']}
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
              </div>
            </div>
          </div>


          <div style={{display: pastTab === 1 ? '' : 'none'}}>
            <div className='list-right-title'>
              RELO Teaching Seminar
            </div>
            <div className='list-right-date'>
              April 20th 2024
            </div>

            <div className='list-item'>
                <div className='list-item-title'>1.Get Them Talking: Speaking Activity for Any Classroom</div>
                <div className='video-panel'>
                  <video
                    preload="none"
                    width={isMobile? '100%' : '628px'}
                    src={'https://file.udty.tech/20240514/1-3.mp4'}
                    style={{outline: "none", objectFit: 'cover'}}
                    controls
                    poster={poster1}
                    disablePictureInPicture
                    controlsList="nodownload"
                  ></video>
                  <div className='video-right'>
                    <div>Seminar Materials</div>
                    <section onClick={() => window.open('https://file.udty.tech/20240419/GetThemTalkingUpdated2024.docx')}>Get Them Talking Worksheet</section>
                  </div>
                </div>
              </div>

            <div className='list-item'>
              <div className='list-item-title'>2.KTV Classroom</div>
              <div className='video-panel'>
                <video
                  preload="none"
                  width={isMobile? '100%' : '628px'}
                  src={'https://file.udty.tech/20240514/3-3.mp4'}
                  style={{outline: "none", objectFit: 'cover'}}
                  controls
                  poster={poster2}
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
                <div className='video-right'>
                  <div>Seminar Materials</div>
                  <section onClick={() => window.open('https://file.udty.tech/20240419/Rolling%20in%20the%20Deep-%20Worksheet.docx')}>Rollin in the Deep Worksheet</section>
                  <section onClick={() => window.open('https://file.udty.tech/20240419/Love%20Yourself-%20Worksheet.docx')}>Love Yourself Worksheet</section>
                  <section onClick={() => window.open('https://file.udty.tech/20240419/Happy-%20Worksheet.docx')}>Happy Worksheet</section>
                  <section onClick={() => window.open('https://file.udty.tech/20240419/Love%20Story%20Worksheet.docx')}>Love Story Worksheet</section>
                </div>
              </div>
            </div>

            <div className='list-item'>
              <div className='list-item-title'>3.Corpus Tools</div>
              <div className='video-panel'>
                <video
                  preload="none"
                  width={isMobile? '100%' : '628px'}
                  src={'https://file.udty.tech/20240514/2-3.mp4'}
                  style={{outline: "none", objectFit: 'cover'}}
                  controls
                  poster={poster3}
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
                <div className='video-right'>
                  <div>Seminar Materials</div>
                  <section onClick={() => window.open('https://file.udty.tech/20240419/CorpusWorkshop2024_Handout1.docx')}>Corpus Tools Worksheet</section>

                  <div className='special-title'>Web Resources</div>
                  <section onClick={() => window.open('https://skell.sketchengine.eu/')}>https://skell.sketchengine.eu/</section>
                  <section onClick={() => window.open('https://linggle.com/')}>https://linggle.com/</section>
                </div>
              </div>
            </div>

            <div className='list-item'>
              <div className='list-item-title'>4.Mental Health Tools</div>
              <div className='video-panel'>
                <video
                  preload="none"
                  width={isMobile? '100%' : '628px'}
                  src={'https://file.udty.tech/20240514/4-3.mp4'}
                  style={{outline: "none", objectFit: 'cover'}}
                  controls
                  poster={poster4}
                  disablePictureInPicture
                  controlsList="nodownload"
                ></video>
                <div className='video-right'>
                  <div>Seminar Materials</div>
                  <section onClick={() => window.open('https://file.udty.tech/20240419/What%20Am%20I%20Feeling%20Today.docx')}>Feeling Worksheet</section>

                  <div className='special-title'>Web Resources</div>
                  <section onClick={() => window.open('http://therapistaid.com/')}>http://therapistaid.com/</section>
                  <section onClick={() => window.open('https://www.uclahealth.org/')}>https://www.uclahealth.org/</section>
                </div>
              </div>
            </div>
          </div>

          <div className='list-right-footer'>
            {
              pastTab === 0 ? <span /> : <img src={imgs['Polygon 3.svg']} onClick={() => setPastTab(0)} />
            }

            Other events
            {
              pastTab === 1 ? <span />: <img src={imgs['Polygon 4.svg']} onClick={() => setPastTab(1)} />
            }
          </div>

      </div>
      </div>

      <div className='footer'>
        <div>
          地区英语项目办公室（RELO）致力于为中国和蒙古国的英语教师提供职业发展机会，以支持公共外交外联。该办公室依托美国大使馆和领事馆的公共事务处开展工作，通过开展英语教学相关的项目和活动，为广大英语教师提供培训机会、专业指导及教学资源。RELO北京办公室是全球26个地区英语项目办公室之一，由美国国务院教育与文化事务局英语项目办公室协调。
          官方网站：
          <a href='https://china.usembassy-china.org.cn/relo/' target='_blank'>https://china.usembassy-china.org.cn/relo/</a>
          <p></p>
          The Regional English Language Office works through the Public Affairs Sections of the US Embassy and US Consulates to create and implement projects and programs related to English language teaching methodology by providing training opportunities, expertise and materials. The RELO Office in Beijing is one of 26 such offices around the world and is coordinated by the U.S. Department of State, Bureau of Educational and Cultural Affairs, Office of English Language Programs. For more information, see：
          <a href='https://china.usembassy-china.org.cn/relo/' target='_blank'>https://china.usembassy-china.org.cn/relo/</a>.
        </div>
        <img src={imgs['image 2083 (1).svg']} />
      </div>

      {/*<div className='footer-container'>*/}
      {/*  <div>Follow us on Weibo</div>*/}
      {/*  <img src={icon} />*/}
      {/*</div>*/}
    </Container>
  );
}

const Container = styled.div`
  
  .list-right-sub-date {
    font-weight: 700;
    font-size: 20px;
    color: #383838;
    margin-bottom: 15px;
    @media (max-width: 991px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  
  .list-right-sub-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #383838;
    margin: -15px auto 15px auto;
    @media (max-width: 991px) {
      font-size: 14px;
      margin: 10px auto;
    }

  }
  
  .list-right-footer {
    display: flex;
    align-items: center;
    column-gap: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #383838;
    margin-top: 50px;
    margin-left: 200px;
    span {
      display: inline-block;
      width: 30px;
      @media (max-width: 991px) {
        width: 15px;
      }
    }
    img {
      cursor: pointer;
      @media (max-width: 991px) {
        width: 15px;
      }
    }
    @media (max-width: 991px) {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 25px;
      justify-content: center;
      column-gap: 20px;
      font-size: 14px;
    }
  }

  .presenters {
    padding: 0 10%;
    margin: -100px auto 30px auto;

    @media (max-width: 991px) {
      padding: 0;
      margin: 30px auto;
    }
  }

  .mobile-show {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }

  .desktop-show {
    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .footer {
    //position: fixed;
    bottom: 0;
    z-index: 3;
    background: #204496;
    padding: 30px 5%;
    display: flex;
    align-items: flex-start;
    column-gap: 10px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;

    @media (max-width: 991px) {
      padding: 15px 5%;
      position: relative;
      flex-flow: column;
      row-gap: 20px;
      align-items: center;

      font-size: 12px;
      line-height: 15px;
      text-align: center;

      color: #FFFFFF;

      & > img {
        width: 100px;
      }

    }

    a {
      color: #FFFFFF !important;
    }
  }

  .event-time-break {
    display: flex;
    align-items: center;
    column-gap: 50px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      column-gap: 10px;
      margin-bottom: 10px;
      margin-left: 12px;
    }

    & > div:nth-child(1) {
      min-width: 100px;
      margin: 0;
      @media (max-width: 991px) {
        min-width: auto;
        font-size: 14px;
      }
    }
    & > div:last-child {
      height: 1px;
      background: #D9D9D9;
      flex: 1;
      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  .event-title-main {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #000000;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .schedule-footer {
    margin: 80px 0;
    text-align: right;
    @media (max-width: 991px) {
      margin: 50px 0;
      & > img {
        width: 100%;
      }
    }
  }

  .schedule-footer2 {
    margin: 80px 0;
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      margin: 50px 0;
      flex-flow: column;
      row-gap: 5px;
      align-items: center;
      & > img:nth-child(2) {
        width: 100%;
      }
      & > img:nth-child(3) {
        width: 100%;
      }
    }
  }
  .schedule-item-detail {
    margin-top: 30px;
  }


  .schedule-item {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    column-gap: 50px;
    margin-bottom: 30px;

    & > div:nth-child(1) {
      min-width: 100px;
    }
  }

  .event-time {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #000000;
    margin-bottom: 25px;

    @media (max-width: 991px) {
      //display: none;
    }
  }

  font-family: 'Montserrat';

  .event-tabs {
    display: flex;
    column-gap: 40px;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      flex-flow: column;
      row-gap: 10px;
      margin-bottom: 20px;
    }
    & > div {
      flex: 1;
      height: 45.07px;
      background: #D9D9D9;
      font-weight: 700;
      font-size: 20px;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 991px) {
        height: 30px;
        font-style: normal;
        font-size: 14px;
      }
      &.selected {
        background: #204496;
        color: white;
      }
    }
  }

  .event-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    margin-bottom: 20px;

    @media (max-width: 991px) {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .event-panel {
    width: 80%;
    margin: 30px auto;

  }



  .footer-container {
    height: 431.47px;
    background: #204496;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    //font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;
    margin-top: 50px;
    & > img {
      width: 200px;
      @media (max-width: 991px) {
        width: 100px;
      }
    }
    @media (max-width: 991px) {
      height: 200px;
      row-gap: 10px;
      font-size: 16px;
      margin-top: 0px;
    }
  }

  .special-title {
    margin-top: 60px;
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }

  .list-item {
    margin-bottom: 40px;
  }

  .video-right {
    & > div {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #C10130;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }

    & > section {
      cursor: pointer;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      text-decoration-line: underline;
      color: #204496;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }

  .video-panel {
    display: flex;
    column-gap: 30px;
    @media (max-width: 991px) {
      margin: 0 auto;
      flex-flow: column;
      justify-content: center;
      row-gap: 15px;
    }

  }
  .list-item-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }

  .list-right-date {
    //font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #204496;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }


  .list-right-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #204496;
    margin-bottom: 20px;


    @media (max-width: 991px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  .list-right {
    padding-left: 5%;
    padding-right: 5%;
    border-left: 4px solid #D9D9D9;
    @media (max-width: 991px) {
      border: none;
    }
  }

  .left-text {
    width: 150px;
    height: 75px;
    //font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 1.5;
    color: #000000;
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .list-panel {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }

  .red-line {
    height: 46px;
    background: #C10130;
    @media (max-width: 991px) {
      height: 30px;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: #FFFFFF;
      display: flex;
      padding-left: 10px;
      align-items: center;
    }
  }
  .middle-panel {
    display: flex;
    //column-gap: 100px;
    justify-content: center;
    width: 80%;
    //margin: 50px auto -100px auto;
    margin: 50px auto 50px auto;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    position: relative;
    z-index: 2;

    @media (max-width: 991px) {
      flex-flow: column;
      row-gap: 25px;
      align-items: center;

      font-size: 14px;
      text-align: center;
      margin: 20px auto;

      & > img {
        width: 100px;
      }
    }
  }
  .title {
    height: 70px;
    background: #204496;
    display: flex;
    align-items: center;
    justify-content: center;

    //font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: #FFFFFF;

    @media (max-width: 991px) {
      font-size: 14px;
      height: 40px;
    }
  }
`

export default App;
