const importAllImg = r => {
  let imgs = {};
  for (let i of r.keys()) {
    const key = i.replace('./', '');
    imgs[key] = r(i);
  }
  return imgs;
}


export default importAllImg;
