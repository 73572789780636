import { useState } from "react";
import styled, { createGlobalStyle } from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
const imgs = importAllImg(require.context('../imgs', false, /\.(png|jpe?g|svg)$/));

function PersonInfo({item: i}) {
  const [morePresenterInfo, setMorePresenterInfo] = useState(false)


  return (
    <Container>
      <div className='presenter-panel'>
        <img src={i.img} />
        <div className='presenter-content'>
          {
            i.showTitle && <div className='presenter-title'>Presenter Bio</div>
          }
          <div className='presenter-text'>
            {
              morePresenterInfo ? i.all : i.short
            }
            <br/>
            <span onClick={() => setMorePresenterInfo(i => !i)}>
              See { morePresenterInfo ? 'less' : 'more'}
            </span>
          </div>
        </div>
      </div>

      <div className='presenter-panel-mobile'>
        {
          i.showTitle && <div className='presenter-title'>Presenter Bio</div>
        }
        <img src={i.img} />

        <div className='presenter-text'>
          {
            morePresenterInfo ? i.all : i.short
          }
          <br/>
          <span onClick={() => setMorePresenterInfo(i => !i)}>
              See { morePresenterInfo ? 'less' : 'more'}
            </span>
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
  .presenter-panel-mobile {
    display: none;
    @media (max-width: 991px) {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 80%;
      margin: 15px auto;
      row-gap: 15px;
    }
  }

  .presenter-text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    span {
      cursor: pointer;
      color: #204496;
    }
  }

  .presenter-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  .presenter-panel {

    display: flex;
    z-index: 2;
    position: relative;
    column-gap: 50px;
    background: white;
    align-items: flex-start;
    margin-bottom: 30px;
    & > img {
      flex: 0 0 174px;
      object-fit: cover;
    }
    @media (max-width: 991px) {
      display: none;
    }
  }
`;

export default PersonInfo;
