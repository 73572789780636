import { useState } from "react";
import styled, { createGlobalStyle } from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
const imgs = importAllImg(require.context('../imgs', false, /\.(png|jpe?g|svg)$/));

function AccordionPanel({item: i}) {
  const [open, setOpen] = useState(false);

  const showIcon = i.content.length > 70;
  const clipText = i.content.slice(0, 70)
  return (
    <Container>
      <div>
        <div className='time'>{i.time}</div>
        <div>
          { showIcon ? (
              <div className='with-icon'>{open ? i.content : clipText} <img
                onClick={() => setOpen(i => !i)}
                src={open ? imgs['left (5).svg']: imgs['left (6).svg']} /></div>
            )

            : <div>{i.content}</div>
          }
        </div>
      </div>
      {/*<div className='content' style={{display: open ? '' : 'none'}}>*/}
      {/*  {i.content}*/}
      {/*</div>*/}
    </Container>
  )
}

const Container = styled.div`
  
  .with-icon {
    display: flex;
    column-gap: 5px;
    align-items: flex-start;
  }
  
  border-bottom: 2px solid #F2F2F7;
  border-radius: 5px;
  //background: #F2F2F7;
  width: 90%;
  margin: 10px auto;
  padding: 5px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #1C1C1E;



  .time-text {
    font-style: normal;
    font-size: 14px;
    color: #1C1C1E;
    
    & > .time {
      font-weight: 700;
      margin-bottom: 3px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1C1C1E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 5px;
  }
  
  .content {
    border-top: 2px solid #F2F2F7;
    padding: 5px 0;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export default AccordionPanel;
